"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StickyPixels = exports.Sticky = void 0;
var Sticky = function (screenDimensions) { return function (x, y, w, h, pos) {
    var width = screenDimensions.width * (w / 100);
    var height = screenDimensions.height * (h / 100);
    var left = screenDimensions.width * (x / 100);
    var top = screenDimensions.height * (y / 100);
    return {
        top: 0,
        left: 0,
        width: width + 'px',
        height: height + 'px',
        transform: "translate3d(".concat(left, "px, ").concat(top, "px, 0)"),
        position: pos > 0 ? 'fixed' : 'absolute',
    };
}; };
exports.Sticky = Sticky;
var StickyPixels = function (screenDimensions) { return function (x, y, w, h, pos) {
    var width = w;
    var height = h;
    var left = x;
    var top = y;
    return {
        top: 0,
        left: 0,
        width: width + 'px',
        height: height + 'px',
        transform: "translate3d(".concat(left, "px, ").concat(top, "px, 0)"),
        position: pos > 0 ? 'fixed' : 'absolute',
    };
}; };
exports.StickyPixels = StickyPixels;
