import React, {useContext, useEffect, useMemo, useState} from 'react';
import style from "./Navbar.module.scss";
import logo from "../assets/logo.svg";
import {FaPhone, FaUser} from "react-icons/fa";
import {
} from "react-router-dom";
import {SessionContext} from "@appidea/rs.bs.customers.react";
import {PagesRegistryContext, Animator} from "react-scroll-motion";
import {logoBarKeyframes, NavbarBottomBarAnimation, navbarBottomKeyframes} from "./Navbar.animation";
import NavbarButton from "./NavbarButton";
import { Logo, LogoText } from '../LandingPage/Logo';

const AnimationWrapper = ({children}) => {
  const {registerAnimation} = useContext(PagesRegistryContext);

  useMemo(() => {
    registerAnimation('navbarBottomBar', NavbarBottomBarAnimation);
  }, [registerAnimation]);

  return (
    <Animator relativeTo="viewport" keyframes={logoBarKeyframes} className={style.headerLogoBar}>
      {children}
    </Animator>
  )
}

const NoAnimationWrapper = ({children}) => {
  return (
    <div className={style.headerLogoBar}>
      {children}
    </div>
  )
}

const Navbar = () => {
  const [setLoginOpen] = useState(false);
  const {user} = useContext(SessionContext);
  const {logout} = useContext(SessionContext);
  const [menuOpen, setMenuOpen] = useState(false);

  const [isMainPage, setIsMainPage] = useState(false);
  useEffect(() => {
    const listener = () => {
      setIsMainPage(window.location.pathname === "/");
    };

    listener();
    window.addEventListener('popstate', listener);
    return () => window.removeEventListener('popstate', listener);
  }, []);

  const BarWrapper = isMainPage ? AnimationWrapper : NoAnimationWrapper;

  return (
    <header className={style.navbar}>
      <BarWrapper>
        <div className={style.headerLogoBarInner}>
          <a href="/">
            <LogoText />
          </a>
        </div>
      </BarWrapper>
      <div className={style.headerInner}>
        <div className={style.buttons}>
          <NavbarButton icon={FaPhone} />

          {user && <>
            {user.type === 'anonymous' && (
              <>
                <NavbarButton onClick={() => setLoginOpen(true)} icon={FaUser} />
              </>
            )}
            {user.type === 'loggedIn' && (
              <>
                <NavbarButton onClick={() => setMenuOpen(o => !o)} icon={FaUser} />
                {menuOpen && (
                  <div className={style.signUpMenu}>
                    <ul>
                      <li>
                        <a href="/panel/#/admin/content/projects">Moje projekty</a>
                      </li>
                      <li>
                        <a href="/panel/#/admin/content/settings">Ustawienia</a>
                      </li>
                      <li>
                        <a onClick={logout}>Logout</a>
                      </li>
                    </ul>
                  </div>
                )}
              </>
            )}
          </>}
        </div>
      </div>

      <Animator keyframes={navbarBottomKeyframes} relativeTo="viewport" className={style.navbarBottomBar} />
    </header>
  )
};

export default Navbar;
