"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClipSquareStatic = void 0;
var ClipSquareStatic = function (viewport) { return function (w, h, radius) {
    var left = (viewport.width - w) / 2;
    var top = (viewport.height - h) / 2;
    var clipPath = "xywh(".concat(left, "px ").concat(top, "px ").concat(w, "px ").concat(h, "px round ").concat(radius, "px)");
    return {
        clipPath: clipPath,
        WebkitClipPath: clipPath
    };
}; };
exports.ClipSquareStatic = ClipSquareStatic;
var Clip = function (viewport) { return function (x, y, w, h, radius) {
    var clipPath = "xywh(".concat(x, "% ").concat(y, "% ").concat(w, "% ").concat(h, "% round ").concat(radius, "px)");
    return {
        clipPath: clipPath
    };
}; };
exports.default = Clip;
