import React from 'react';
import style from "../App.module.scss";
import HeroTwo from "./HeroTwo";
import FirstSlide from "./FirstSlide";
import SolutionAmos from './SolutionAmos';

const LandingPage = () => {
  return (
    <>
      <HeroTwo />
      <FirstSlide />
      <SolutionAmos />

      
      <section id="contact" className={style.contact}>
          <div className={style.pageInner + ' ' + style.bottom}>
            <section className={style.contactForm}>
              <h2>
                Pozostańmy w kontakcie
              </h2>

              <p style={{marginBottom: '30px'}}>
                Contact us:
              </p>

              <div className={style.contactRow}>
                <div className={style.contactCol} style={{marginBottom: '10px'}}>
                  <a href="mailto:kontakt@appidea.pl"><b>kontakt@appidea.pl</b></a>
                </div>
                <div className={style.contactCol}>
                  <div style={{marginBottom: '10px'}}>
                    Sales:
                  </div>

                  <div>
                    <a href="tel:+48669055170">
                      Poland: <b>+48 669 055 170</b>
                    </a>
                  </div>

                  <div>
                    <a href="tel:+420775843070">
                      Czech Republic: <b>+420 775 843 070</b>
                    </a>
                  </div>
                </div>
              </div>

            </section>
          </div>

          <div className={style.copy}>
            <div className={style.copyCol}>
              <b>© 2024 Appidea.pl</b>
              <br/>
              ul. Chmielna 73 (VARSO Place)<br/>
              00-801 Warsaw, Poland<br/>
              NIP: 956 228 39 82
            </div>
          </div>
        </section>
    </>
  );
};

export default LandingPage;
