import React, { useCallback, useEffect } from "react";
import style from "../../App.module.scss";
import { Logo } from "../Logo";
import QRCode from "qrcode";

const buttonStyle = {
  padding: "20px",
  borderLeftColor: "#6ac7ba",
  borderTopColor: "#6ac7ba",
  borderBottomColor: "rgb(22, 187, 231)",
  borderRightColor: "rgb(22, 187, 231)",
  borderWidth: "10px",
  margin: "20px",
};

const RegistrationInput = ({ label, lang, ...props }) => {
  return (
    <label className={style.inputField}>
      <div className={style.fName}>{label[lang]}</div>
      <div className={style.fField}>
        <input type="email" {...props} />
      </div>
    </label>
  );
};

const ValidRegistration = ({ identifier, onClose }) => {
  const [qrUrl, setQrUrl] = React.useState(null);
  useEffect(() => {
    QRCode.toDataURL(identifier, function (err, url) {
      setQrUrl(url);
    });
  }, [identifier]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: "auto",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.5)",
        }}
      ></div>

      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          padding: "20px",
          margin: "auto",
          width: "400px",
          height: "600px",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#071b4e",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <h2 style={{ color: "#FFF" }}>Konto zostało zarejestrowane.</h2>
        <p style={{ color: "#FFF" }}>
          Przyjdź na stanowisko RAPIDSHOP z poniższym KODem, aby odebrać
          upominek od Appidea.pl.
        </p>

        <div style={{ width: "100%", marginTop: "60px", textAlign: "center" }}>
          <img src={qrUrl} alt="QR Code" />
        </div>

        <div style={{ marginTop: "30px" }}>
          <button onClick={onClose}>OK</button>
        </div>
      </div>
    </>
  );
};

const SolutionAmos = () => {
  const [lang, setLang] = React.useState('pl');
  const [formState, setFormState] = React.useState({});
  const [consents, setConsents] = React.useState([]);
  const [consentsState, setConsentsState] = React.useState({});
  const [error, setError] = React.useState(null);
  const [showQrCode, setShowQrCode] = React.useState(null);

  const fetchConsents = useCallback(async () => {
    const consentsResp = await fetch("/customers/consent?category=reg");
    const consents = await consentsResp.json();
    setConsents(consents.data);

    const tmpConsentState = {};
    consents.data.forEach((consent) => {
      tmpConsentState[consent.consentIdentifier] = false;
    });
    setConsentsState(tmpConsentState);
  }, []);

  useEffect(() => {
    fetchConsents();
  }, [fetchConsents]);

  console.log(consentsState);

  const submitForm = async () => {
    const formStateCopy = { 
      ...formState,
      company_name: formState['company_name:name'] + '|' + formState['company_name:link']
    };

    const response = await fetch("/customers/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...formStateCopy,
        accepted_consents: consentsState,
      }),
    });

    if (!response.ok) {
      setError("Błąd rejestracji");
    }

    const json = await response.json();
    if (json.status === "VALIDATION") {
      if (json.issues.email === "EV05") {
        setError(
          "Sprawdź poprawność adresu email. Być może jest już zarejestrowany."
        );
        return;
      }
      setError(
        "Niektóre pola mogą zawierać niepoprawne wartości. Sprawdź poprawnosc danych"
      );
      return;
    }

    if (json.data) {
      setShowQrCode(json.data.identifier);
    }
  };

  const getFieldInt = (name) => ({
    value: formState[name],
    onChange: (e) => setFormState({ ...formState, [name]: e.target.value }),
  });

  const onSubmit = (e) => {
    e.preventDefault();
    submitForm();
  };

  return (
    <div
      style={{
        position: "relative",
        zIndex: 99999,
        backgroundColor: "#f3f3f3",
      }}
    >
      <section className={style.pageInner} style={{ marginBottom: 0 }}>
        <div className={style.heading}>
          <span>Jesteś właścicielem obiektu gastronomicznego?</span>
          <h2>Zarejestruj konto za darmo</h2>
          <span>i odbierz upominek na targach Gastro Quick Service (stanowisko C1.1)</span>
        </div>

        <div style={{marginTop: '10px'}}>
          <button onClick={() => setLang('pl')}>PL</button> /
          <button onClick={() => setLang('en')}>EN</button>
        </div>

        <form onSubmit={onSubmit}>
          <div style={{ marginTop: "60px" }}>
            <h1>Załóż darmowe konto Appidea!</h1>
            <p>
              Bądź gotowy na uruchomienie instalacji Rapidshopa dla Twojego
              punktu sprzedaży.
            </p>

            <RegistrationInput
              label={{
                pl: 'Podaj nazwę swojej firmy',
                en: 'Company Name'
              }}
              lang={lang}
              type="text"
              {...getFieldInt("company_name:name")}
            />
            <RegistrationInput
              label={{
                pl: 'Z jakiego kraju jesteŚ?',
                en: 'Country'
              }}
              lang={lang}
              type="text"
              {...getFieldInt("country")}
            />
            <RegistrationInput
              label={{
                pl: 'Link Google Maps do Twojej restauracji',
                en: 'Google Maps link to your restaurant'
              }}
              lang={lang}
              type="text"
              {...getFieldInt("company_name:link")}
            />
            <RegistrationInput
              label={{
                pl: 'Twój NIP',
                en: 'VAT ID'
              }}
              lang={lang}
              type="text"
              {...getFieldInt("vat_id")}
            />
            <RegistrationInput
              label={{
                pl: 'Podaj swoje imie',
                en: 'Your name'
              }}
              lang={lang}
              type="text"
              {...getFieldInt("first_name")}
            />
            <RegistrationInput
              label={{
                pl: 'Twój email',
                en: 'Your email'
              }}
              lang={lang}
              type="email"
              {...getFieldInt("email")}
            />
            <RegistrationInput
              label={{
                pl: 'Twój telefon',
                en: 'Your phone number'
              }}
              lang={lang}
              type="phone"
              {...getFieldInt("phone")}
            />
            <RegistrationInput
              label={{
                pl: 'WysyŚl hasło... (8 liter, małe i duże znaki, cyfry)',
                en: 'Your password (8 characters, small and capital letters, numbers)'
              }}
              lang={lang}
              type="password"
              {...getFieldInt("password")}
            />

            <div
              style={{
                marginBottom: "60px",
                marginTop: "60px",
                maxWidth: "1024px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {consents.map((consent) => (
                <div
                  key={consent.consentIdentifier}
                  style={{ marginBottom: "6px" }}
                >
                  <input
                    type="checkbox"
                    checked={consentsState[consent.consentIdentifier]}
                    onChange={(e) =>
                      setConsentsState((c) => ({
                        ...c,
                        [consent.consentIdentifier]: e.target.checked,
                      }))
                    }
                  />
                  <span style={{ marginLeft: "10px" }}>
                    {consent.requiredToRegister && (
                      <i style={{ color: "red" }}>*</i>
                    )}{" "}
                    {consent.shortContent}
                  </span>
                </div>
              ))}
            </div>

            {error && (
              <div style={{ color: "red", marginBottom: "20px" }}>{error}</div>
            )}

            <div style={{ marginBottom: "60px" }}>
              <button style={buttonStyle}>Zarejestruj się</button>
            </div>

            <p style={{marginTop: '60px'}}>
              Jesteś przedstawicielem innej branży? Napisz do nas: <b>kontakt@rapidshop.pl</b>
            </p>
          </div>
        </form>

        <Logo />
      </section>

      {showQrCode && <ValidRegistration identifier={showQrCode} onClose={() => {
        setShowQrCode(null);
        setFormState({});
      }} />}
    </div>
  );
};

export default SolutionAmos;
