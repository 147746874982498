import {createBrowserRouter, RouterProvider} from "react-router-dom";
import React from "react";
import LandingPage from "./LandingPage";
import Navbar from "./Navbar";
import style from "./App.module.scss";
import {ScrollContainer} from "react-scroll-motion";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage/>,
    }
  ]);

  return (
    <ScrollContainer>
      <div className={style.app}>
        <Navbar />
        <RouterProvider router={router} />
      </div>
    </ScrollContainer>
  );
}

export default App;
