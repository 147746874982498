"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.range = exports.clamp = exports.invlerp = exports.lerp = exports.SimpleInterpolation = void 0;
var SimpleInterpolation = function (from, to, value) {
    return from * (1 - value) + to * value;
};
exports.SimpleInterpolation = SimpleInterpolation;
var lerp = function (x, y, a) { return x * (1 - a) + y * a; };
exports.lerp = lerp;
var invlerp = function (x, y, a) { return (0, exports.clamp)((a - x) / (y - x)); };
exports.invlerp = invlerp;
var clamp = function (a, min, max) {
    if (min === void 0) { min = 0; }
    if (max === void 0) { max = 1; }
    return Math.min(max, Math.max(min, a));
};
exports.clamp = clamp;
var range = function (x1, y1, x2, y2, a) { return (0, exports.lerp)(x2, y2, (0, exports.invlerp)(x1, y1, a)); };
exports.range = range;
