"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.predefinedAnimations = void 0;
var FadeAnimation_1 = require("./FadeAnimation");
var MoveAnimation_1 = require("./MoveAnimation");
var StickyAnimation_1 = require("./StickyAnimation");
var ZoomAnimation_1 = require("./ZoomAnimation");
var Clip_1 = __importStar(require("./Clip"));
var SvgStrokeProgress_1 = require("./SvgStrokeProgress");
exports.predefinedAnimations = new Map();
exports.predefinedAnimations.set('zoom', { id: 'zoom', item: ZoomAnimation_1.Zoom });
exports.predefinedAnimations.set('fade', { id: 'fade', item: FadeAnimation_1.Fade });
exports.predefinedAnimations.set('move', { id: 'move', item: MoveAnimation_1.Move });
exports.predefinedAnimations.set('sticky', { id: 'sticky', item: StickyAnimation_1.Sticky });
exports.predefinedAnimations.set('clip', { id: 'clip', item: Clip_1.default });
exports.predefinedAnimations.set('clipSquareStatic', { id: 'clipSquareStatic', item: Clip_1.ClipSquareStatic });
exports.predefinedAnimations.set('stickyPixels', { id: 'stickyPixels', item: StickyAnimation_1.StickyPixels });
exports.predefinedAnimations.set('svgStrokeProgress', { id: 'svgStrokeProgress', item: SvgStrokeProgress_1.SvgStrokeProgress });
