"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SvgStrokeProgress = void 0;
var SvgStrokeProgress = function () {
    return function (progress) {
        return {
            strokeDasharray: "1000 1000",
            strokeDashoffset: 1000 - (progress * 1000),
        };
    };
};
exports.SvgStrokeProgress = SvgStrokeProgress;
