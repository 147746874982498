"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.singleKeyframe = void 0;
var singleKeyframe = function (animation) { return ([
    {
        start: 0,
        end: 1,
        animation: animation
    }
]); };
exports.singleKeyframe = singleKeyframe;
