import React, { useContext } from "react";
import { Animator, ScrollPage, ViewportContext } from "react-scroll-motion";
import style from "./FirstSlide.module.scss";
import cover from "../../assets/cover.png";
import appStyle from "../../App.module.scss";
import classNames from "classnames";
import { restMargins } from "../../animatorUtils";
import background from "./pexels-photo-3201921.jpeg";
import laskaAi from "./img-HXDo4Hkq58IkMzCJEaygI3Yn.png";

const FirstSlide = () => {
  const viewport = useContext(ViewportContext);

  const header1Keyframes = [
    {
      time: 0,
      // animation: [['stickyPixels', [...restMargins(1024, 768, viewport), 0]]]
      animation: [
        [
          "stickyPixels",
          [0, 0, viewport.width, viewport.height, 0],
          ["move", [0, 0]],
        ],
      ],
    },
    {
      time: 0.01,
      animation: [
        ["stickyPixels", [0, 0, viewport.width, viewport.height, 1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 1,
      animation: [
        ["stickyPixels", [0, 0, viewport.width, viewport.height, 1]],
        ["move", [-100, 0]],
      ],
    },
  ];

  const header2Keyframes = [
    {
      time: 0,
      animation: [
        ["stickyPixels", [...restMargins(1024, 768, viewport), 0]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 0.01,
      animation: [
        ["stickyPixels", [...restMargins(1024, 768, viewport), 1]],
        ["move", [0, 0]],
      ],
    },
    {
      time: 1,
      animation: [
        ["stickyPixels", [...restMargins(1024, 768, viewport), 1]],
        ["move", [-100, 0]],
      ],
    },
  ];

  const movingLaska = [
    {
      time: 0,
      animation: [["move", [0, 0]]],
    },
    {
      time: 1,
      animation: [["move", [-300, 0]]],
    },
  ];

  return (
    <ScrollPage
      page={1}
      length={3}
      className={style.container}
      style={{ background: " #00F" }}
    >
      <section>
        <Animator
          tag="div"
          keyframes={header1Keyframes}
          style={{ textAlign: "left" }}
        >
          <img
            src={background}
            style={{
              width: "calc(100% + 100px)",
              height: "100%",
              objectFit: "cover",
            }}
            alt=""
          />
        </Animator>

        <Animator
          tag="div"
          keyframes={header2Keyframes}
          style={{ textAlign: "left" }}
        >
          <div style={{ width: viewport.width + "px", position: "absolute" }}>
            <Animator
              keyframes={movingLaska}
              style={{ position: "absolute", right: 0, top: 0, zIndex: 9999 }}
            >
              <img src={laskaAi} alt="" />
            </Animator>
            <div
              style={{
                color: "#000",
                position: "relative",
                backgroundColor: "#FFF",
                width: "33%",
                padding: "30px",
              }}
            >
              <h2 style={{padding: 'none', margin: 0}}>Tworzymy zupełnie nowy POS</h2>
              <p>POS, który wykorzystuje m. in. AI i GPT do usprawniania codziennych zakupów w Twoim punkcie</p>
            </div>
          </div>
        </Animator>
      </section>
    </ScrollPage>
  );
};

export default FirstSlide;
