import React, { useContext } from "react";
import { Animator, ScrollPage, ViewportContext } from "react-scroll-motion";
import style from "./Hero.module.scss";
import cover from "./8426096-uhd_4096_2160_25fps.mp4";
import {
  clonedHeaderKeyframes,
  headerKeyframes,
  imageKeyframes,
  real,
} from "./Hero.animation";
import { restMargins } from "../../animatorUtils";
import k2mini from "../../assets/k2mini.png";
import v2splus from "../../assets/v2splus.png";
import t3pro from "../../assets/t3pro.png";
import Bg from "./bg";
import { Logo } from "../Logo";

const abs = {
  position: "absolute",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  width: "100%",
  height: "100%",
};

const firstText = "Do dobrych, klasycznych rozwiązań";
const secondText = "dodajemy szczyptę nowoczesnych technologii.";

const Hero = () => {
  const viewport = useContext(ViewportContext);

  const fillShadow = [
    {
      time: 0,
      animation: [
        ["stickyPixels", [...restMargins(300, 600, viewport), 1]],
        ["fade", [0]],
      ],
    },
    {
      time: 0.1,
      animation: [["fade", [0]]],
    },
    {
      time: 0.2,
      animation: [["fade", [1]]],
    },
    {
      time: 0.5,
      animation: [["fade", [1]]],
    },
  ];

  const k2sticky = ["stickyPixels", [...restMargins(459, 928, viewport), 1]];

  const k2miniKeyframes = [
    {
      time: 0,
      animation: [k2sticky, ["move", [0, viewport.height]]],
    },
    {
      time: 0.3,
      animation: [k2sticky, ["move", [0, viewport.height]]],
    },
    {
      time: 0.4,
      animation: [k2sticky, ["move", [0, 0]]],
    },
    {
      time: 1,
      animation: [k2sticky, ["move", [0, 0]]],
    },
  ];

  const [mgLeft] = restMargins(387, 101, viewport);
  const imageKeyframes = [
    {
      time: 0,
      animation: [
        ["stickyPixels", [mgLeft, viewport.height / 2 - 200, 387, 101, 1]],
        ["fade", [1]],
        ["zoom", [1]],
      ],
    },
    {
      time: 0.3,
      animation: [
        ["stickyPixels", [mgLeft, 100, 387, 101, 1]],
        ["opacity", [0.5]],
        ["fade", [0.5]],
      ],
    },
    {
      time: 0.4,
      animation: [
        ["stickyPixels", [mgLeft, 100, 387, 101, 1]],
        ["opacity", [0]],
        ["fade", [0]],
      ],
    },
  ];

  return (
    <ScrollPage
      page={0}
      length={4}
      style={{ background: "#FFF", position: "relative" }}
    >
      <Animator tag="div" keyframes={imageKeyframes}>
        <Logo />
      </Animator>

      <Animator keyframes={k2miniKeyframes}>
        <img
          src={k2mini}
          alt="k2mini"
          style={{
            width: "100%",
            height: "100%",
            left: "0px",
            top: "121px",
            position: "relative",
            objectFit: "cover",
          }}
        />
      </Animator>

      <Animator
        keyframes={headerKeyframes}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "rgb(123, 207, 241)",
        }}
      >
        <h1>{firstText}</h1>
      </Animator>

      <Animator
        tag="div"
        keyframes={fillShadow}
        style={{
          margin: "auto",
          borderRadius: "10px",
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.3)",
          display: "flex", alignItems: 'center', justifyContent: 'center'
        }}
      >
        <Animator tag="h1" keyframes={real} style={{ textAlign: "center" }}>
          {secondText}
        </Animator>
      </Animator>
    </ScrollPage>
  );
};

export default Hero;

/*
<div className={style.hero}>
          <div className={style.heroContent}>
            <div className={style.heroContentTexts}>
              <h2>The world is built on ideas.</h2>
              <h1>We believe in Your</h1>
              <img className={style.bigLogo} src={logo} alt="appidea"/>
            </div>
          </div>
        </div>
 */
