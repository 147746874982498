"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRegistry = void 0;
var react_1 = require("react");
var useRegistry = function (initialEntries) {
    if (initialEntries === void 0) { initialEntries = new Map(); }
    var _a = (0, react_1.useState)(initialEntries), registry = _a[0], setRegistry = _a[1];
    var get = (0, react_1.useMemo)(function () { return registry.get.bind(registry); }, [registry]);
    var register = (0, react_1.useCallback)(function (id, item) {
        var newEntry = {
            id: id,
            item: item
        };
        setRegistry(function (prevRegistry) {
            var newRegistry = new Map(prevRegistry);
            newRegistry.set(id, newEntry);
            return newRegistry;
        });
    }, []);
    var unregister = (0, react_1.useCallback)(function (id) {
        setRegistry(function (prevRegistry) {
            var newRegistry = new Map(prevRegistry);
            newRegistry.delete(id);
            return newRegistry;
        });
    }, []);
    return { get: get, registry: registry, register: register, unregister: unregister };
};
exports.useRegistry = useRegistry;
