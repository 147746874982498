import React from 'react';
import styles from './Navbar.module.scss';
import { Animator } from 'react-scroll-motion';
import {navbarIconStrokeKeyframes, navbarIconKeyframes, navbarButtonKeyframes} from "./Navbar.animation";

const NavbarButton = ({icon: Icon, ...props}) => {
  return (
    <Animator tag="button" relativeTo="viewport" keyframes={navbarButtonKeyframes} className={styles.button} {...props}>
      <svg viewBox="0 0 60 60" className={styles.buttonCircle}>
        <Animator relativeTo="viewport" keyframes={navbarIconStrokeKeyframes} tag="circle" cx={30} cy={30} r={30} className={styles.buttonStroke} />
      </svg>

      <Animator relativeTo="viewport" keyframes={navbarIconKeyframes} tag={Icon} size={20} color="#000" />
    </Animator>
  );
};

export default NavbarButton;
