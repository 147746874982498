"use strict";
"use client";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var constants_1 = require("./constants");
var stores_1 = require("./stores");
var ViewportContext_1 = require("./ViewportContext");
var registry_1 = require("./utils/registry");
var animations_1 = require("./animations");
var findRange = function (ranges, test) {
    var foundRangeIndex = ranges.findIndex(function (_a) {
        var start = _a[0], end = _a[1];
        return test >= start && test <= end;
    });
    if (foundRangeIndex === -1) {
        return null;
    }
    return { index: foundRangeIndex, range: ranges[foundRangeIndex] };
};
var _window = typeof window !== "undefined" ? window : undefined;
// const debugEl = document.createElement('div');
// debugEl.style.position = 'fixed';
// debugEl.style.top = '0';
// debugEl.style.right = '0';
// debugEl.style.width = '200px';
// debugEl.style.height = '100px';
// debugEl.style.backgroundColor = 'white';
// debugEl.style.zIndex = '9999';
// document.body.appendChild(debugEl);
var progressForRange = function (range, currentY) {
    var top = range[0], bottom = range[1];
    var topOfRange = top - currentY;
    return -(topOfRange / (bottom - top));
};
var ScrollContainer = function (props) {
    var _a = props.snap, snap = _a === void 0 ? "none" : _a, children = props.children, _scrollParent = props.scrollParent, style = props.style, className = props.className;
    var scrollParent = _scrollParent || _window;
    var _b = (0, react_1.useState)(constants_1.initialScrollData), scrollData = _b[0], setScrollData = _b[1];
    var scrollTimer = (0, react_1.useRef)();
    var viewport = (0, react_1.useContext)(ViewportContext_1.ViewportContext);
    var _c = (0, registry_1.useRegistry)(), registerPage = _c.register, unregisterPage = _c.unregister, getPage = _c.get, pages = _c.registry;
    var _d = (0, registry_1.useRegistry)(animations_1.predefinedAnimations), registerAnimation = _d.register, unregisterAnimation = _d.unregister, getAnimation = _d.get;
    var pagesArr = (0, react_1.useMemo)(function () { return Array.from(pages.values()).sort(function (a, b) { return a.item.page - b.item.page; }); }, [pages]);
    var pageBoundaries = (0, react_1.useMemo)(function () {
        var data = pagesArr.reduce(function (acc, page) {
            return {
                lastTop: acc.lastTop + (page.item.length * viewport.height),
                ranges: __spreadArray(__spreadArray([], acc.ranges, true), [[acc.lastTop, acc.lastTop + (page.item.length * viewport.height)]], false)
            };
        }, { lastTop: 0, ranges: [] });
        return data.ranges;
    }, [pages, viewport.height]);
    var scrollEvent = (0, react_1.useCallback)(function () {
        if (snap !== "none" && scrollTimer.current)
            clearTimeout(scrollTimer.current);
        var currentY = scrollParent === window
            ? window.pageYOffset
            : scrollParent.scrollTop;
        var totalPage = pagesArr.reduce(function (acc, page) {
            return acc + page.item.length;
        }, 0);
        var totalHeight = totalPage * (viewport.height - 1);
        var totalProgress = currentY / totalHeight; // 전체 페이지 진행률 0 ~ 1
        var range = findRange(pageBoundaries, currentY);
        if (!range) {
            return;
        }
        var currentProgress = progressForRange(pageBoundaries[range.index], currentY);
        var nextProgress = pageBoundaries[range.index + 1] ? progressForRange(pageBoundaries[range.index + 1], currentY) : null;
        var currentPage = range.index;
        // debugEl.innerText = `currentY: ${currentY}, totalPage: ${totalPage}, totalHeight: ${totalHeight}, totalProgress: ${totalProgress}, currentPage: ${currentPage}, currentProgress: ${currentProgress}, nextProgress: ${nextProgress}`;
        setScrollData(function (scrollData) {
            return (__assign(__assign({}, scrollData), { currentY: currentY, totalPage: totalPage, totalHeight: totalHeight, totalProgress: totalProgress, nextProgress: nextProgress, currentPage: currentPage, currentProgress: currentProgress }));
        });
        if (snap !== "none") {
            scrollTimer.current = setTimeout(function () {
                var newCurrentPage = currentPage + 1;
                var newCurrentY = currentY;
                if (snap === "mandatory" || currentProgress > 0.8)
                    newCurrentY = pageBoundaries[newCurrentPage][0];
                if (newCurrentY !== currentY)
                    window.scrollTo({
                        top: newCurrentY,
                        behavior: "smooth",
                    });
            }, 50);
        }
    }, [children, scrollParent, snap, setScrollData, pageBoundaries, viewport.height]);
    (0, react_1.useEffect)(function () {
        if (scrollParent) {
            scrollEvent();
            scrollParent.addEventListener("scroll", scrollEvent, {
                passive: true,
            });
            scrollParent.addEventListener("resize", scrollEvent, {
                passive: true
            });
            return function () { return scrollParent.removeEventListener("scroll", scrollEvent); };
        }
    }, [scrollEvent, scrollParent]);
    return (react_1.default.createElement("div", { style: __assign({ margin: 0, padding: 0, userSelect: "none" }, style), className: className },
        react_1.default.createElement(stores_1.PagesRegistryContext.Provider, { value: { registerPage: registerPage, unregisterPage: unregisterPage, registerAnimation: registerAnimation, unregisterAnimation: unregisterAnimation, getAnimation: getAnimation } },
            react_1.default.createElement(stores_1.ScrollDataContext.Provider, { value: scrollData }, children))));
};
var WrappedScrollContainer = function (_a) {
    var children = _a.children, props = __rest(_a, ["children"]);
    return react_1.default.createElement(ViewportContext_1.ViewportController, null,
        react_1.default.createElement(ScrollContainer, __assign({}, props), children));
};
exports.default = WrappedScrollContainer;
