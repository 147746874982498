
export const mainboxKeyframes = (viewport) => [
  {
    time: 0,
    animation: [
      ['clipSquareStatic', [viewport.width, viewport.height, 0]],
      ['sticky', [0, 0, 100, 100, 1]]
    ]
  },
  {
    time: 0.2,
    animation: [
      ['clipSquareStatic', [320, 540, 10]],
    ]
  },
  {
    time: 0.4,
    end: 1,
    animation: [
      ['clipSquareStatic', [320, 540, 10]],
    ]
  }
]

export const clonedHeaderKeyframes = [
  {
    time: 0,
    animation: [
      [ 'move', [0, 0] ]
    ]
  },
  {
    time: 0.2,
    animation: [[ 'move', [0, 0] ]]
  },
  {
    time: 0.2,
    animation: [['fade', [1]]]
  },
  {
    time: 0.4,
    animation: [['fade', [0]]]
  }
]

export const headerKeyframes = [
  {
    time: -0.1,
    animation: [['sticky', [0, 0, 100, 100, 1]]]
  },
  {
    time: 0,
    animation: [['sticky', [0, 0, 100, 100, 1]]]
  },
  {
    time: 0.2,
    animation: [['fade', [1]]]
  },
  {
    time: 0.4,
    animation: [['fade', [0]]]
  }
];

export const fill = [
  {
    time: 0,
    animation: [['move', [1000, 0]]]
  },
  {
    time: 0.2,
    animation: [['fade', [0]]]
  },
  {
    time: 0.25,
    animation: [['fade', [1]]]
  },
  {
    time: 0.4,
    animation: [['move', [0, 0]]]
  }
]

export const real = [
  {
    time: 0,
    animation: [['move', [-100, 0]]]
  },
  {
    time: 0.4,
    animation: [['move', [0, 0]]]
  },
  {
    time: 0.5,
    animation: [['move', [0, 0]]]
  }
]