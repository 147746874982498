"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var computeStyle = function (style) {
    var _a;
    if (style === void 0) { style = {}; }
    var params = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        params[_i - 1] = arguments[_i];
    }
    var computedStyle = __assign({}, style);
    for (var key in computedStyle) {
        if (typeof computedStyle[key] === "function") {
            computedStyle[key] = (_a = computedStyle)[key].apply(_a, params);
        }
    }
    return computedStyle;
};
exports.default = computeStyle;
