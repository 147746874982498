"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Move = void 0;
var Move = function () { return function (dx, dy) {
    if (dx === void 0) { dx = 0; }
    if (dy === void 0) { dy = 100; }
    return ({
        transform: "translate3d(".concat(dx, "px, ").concat(dy, "px, 0)")
    });
}; };
exports.Move = Move;
